import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core';
import { themeSubject, showNavSubject } from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import LandingTopNav from './components/TopNav/TopNav';
import GraphQlClient from './shared/GraphQlClient';

const generateClassName = createGenerateClassName({
  productionPrefix: 'navbar',
  seed: 'navbar',
});

const Root = () => {
  const [theme, setNavTheme] = useState(themeSubject.getValue());
  const [displayNav, setDisplayNav] = useState(showNavSubject.getValue());
  useEffect(() => {
    themeSubject.subscribe(setNavTheme);
    showNavSubject.subscribe(setDisplayNav);
  }, []);

  if (!displayNav) {
    return null;
  }

  return (
    <BrowserRouter>
      <GraphQlClient>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <ToastContainer />
              <LandingTopNav />
            </AuthProvider>
          </ThemeProvider>
        </StylesProvider>
      </GraphQlClient>
    </BrowserRouter>
  );
};

export default Root;
